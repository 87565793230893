import { TimelineMax } from 'gsap';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
// import 'debug.addIndicators';

let scene;

function setup() {
    const controller = new ScrollMagic.Controller();

    let wipeAnimation = new TimelineMax().to("#heroSections", 1, {x:  `-83.85%`});

    scene = new ScrollMagic.Scene({
        triggerElement: "#sectionsWrapper",
        triggerHook: "onLeave",
        duration: "600%"
    })
        .setPin("#sectionsWrapper")
        .setTween(wipeAnimation)
        // .addIndicators()
        .addTo(controller);
}

if (window.outerWidth >= 1024) {
    setup();
}

let resizeTimer;
window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
        if (document.querySelector('[data-scrollmagic-pin-spacer]')) {
            scene.destroy(true);
        }
        if (window.outerWidth >= 1024) {
            setup()
        }
    }, 250);
});
